<template>
  <div>
    <b-sidebar id="sidebar-negotiated-rate-edit" :visible="negotiatedRateEdition" width="35%" @change="editionChange" @hidden="clearNegotiatedRate()">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('negotiated_rate.sidebar.' + negotiatedRateEditionMode) }}
          </h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer #default="{ invalid, validate, untouched }" ref="negotiated-rate-observer">
          <b-form class="p-2" @submit.prevent="validate().then(submitNegotiatedRate)">
            <b-row>
              <b-col cols="8">
                <!-- company name -->
                <validation-provider #default="{ errors }" :name="$t('negotiated_rate.company_name')" rules="required" class="validation-required">
                  <b-form-group :label="$t('negotiated_rate.company_name')" label-for="company-name">
                    <b-overlay :show="negotiatedRateLoading">
                      <b-form-input
                        id="negotiated-rate-company-name"
                        v-model="negotiatedRate.edited.companyName"
                        :placeholder="$t('negotiated_rate.company_name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-overlay>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <!--Status-->
                <validation-provider #default="{ errors }" :name="$t('common.status')" rules="required" class="validation-required">
                  <b-form-group :label="$t('common.status')" label-for="negotiated-rate-status">
                    <b-overlay :show="negotiatedRateStatusLoading || negotiatedRateLoading">
                      <v-select
                        id="negotiated-rate-status"
                        v-model="negotiatedRate.edited.status"
                        :options="negotiatedRateStatus"
                        :reduce="name => name.value"
                        label="text"
                        :clearable="false"
                      >
                        <template #option="{ text }">
                          <b-badge v-if="text === 'ACTIVE'" pill variant="light-success"> {{ upperFirst(lowerCase(text)) }}</b-badge>
                          <b-badge v-if="text === 'INACTIVE'" pill variant="light-danger"> {{ upperFirst(lowerCase(text)) }}</b-badge>
                        </template>
                        <template #selected-option="{ text }">
                          <b-badge v-if="text === 'ACTIVE'" pill variant="light-success"> {{ upperFirst(lowerCase(text)) }}</b-badge>
                          <b-badge v-if="text === 'INACTIVE'" pill variant="light-danger"> {{ upperFirst(lowerCase(text)) }}</b-badge>
                        </template>
                        <template #no-options> {{ $t('common.no_option_found') }} </template>
                      </v-select>
                    </b-overlay>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <!-- icvat -->
                <validation-provider #default="{ errors }" :name="$t('negotiated_rate.intra_community_vat')" rules="required" class="validation-required">
                  <b-form-group :label="$t('negotiated_rate.intra_community_vat')" label-for="tva-intra">
                    <b-overlay :show="negotiatedRateLoading">
                      <b-form-input
                        id="negotiated-rate-intracommmunity-vat-number"
                        v-model="negotiatedRate.edited.intracommunityVatNumber"
                        :disabled="negotiatedRateEditionMode === 'edit'"
                        :placeholder="$t('negotiated_rate.intra_community_vat')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-overlay>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <!--Start at-->
                    <validation-provider #default="{ errors }" :name="$t('common.start_at')" rules="required" class="validation-required">
                      <b-form-group :label="$t('common.start_at')" label-for="start-at">
                        <b-overlay :show="negotiatedRateLoading">
                          <b-form-datepicker
                            id="start-at"
                            v-model="negotiatedRate.edited.startAt"
                            placeholder="Start at"
                            :locale="$i18n.locale"
                            :max="negotiatedRate.edited.endAt"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-overlay>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <!--End at-->
                    <validation-provider #default="{ errors }" :name="$t('common.end_at')" rules="required" class="validation-required">
                      <b-form-group :label="$t('common.end_at')" label-for="end-at">
                        <b-overlay :show="negotiatedRateLoading">
                          <b-form-datepicker
                            id="end-at"
                            v-model="negotiatedRate.edited.endAt"
                            placeholder="End at"
                            :locale="$i18n.locale"
                            :min="negotiatedRate.edited.startAt"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-overlay>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <!-- cancellation policy -->
                <validation-provider #default="{ errors }" :name="$tc('cancellation_policy.title', 1)" rules="required" class="validation-required">
                  <b-form-group :label="$tc('cancellation_policy.title', 1)" label-for="cancellation-policy">
                    <b-overlay :show="cancellationPoliciesNameLoading || negotiatedRateLoading">
                      <b-form-select
                        id="cancellation-policy"
                        v-model="negotiatedRate.edited.cancellationPolicy.id"
                        :options="cancellationPoliciesName"
                        value-field="id"
                        text-field="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-overlay>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="$can('CANCELLATION_POLICY_ADD')" cols="4" class="m-auto">
                <b-link :to="{ name: 'cancellationPolicyAdd' }">
                  {{ $t('negotiated_rate.sidebar.add_cancellation_policy') }}
                </b-link>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <validation-provider #default="{ errors }" :name="$tc('price_management.title', 1)">
                  <b-form-group :label="$tc('price_management.title', 1)" label-for="price-management">
                    <b-overlay :show="pricesManagementNameLoading || negotiatedRateLoading">
                      <b-form-select
                        id="price-management"
                        v-model="negotiatedRate.edited.priceManagement.id"
                        :options="pricesManagementName"
                        value-field="id"
                        text-field="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-overlay>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="$can('PRICE_MANAGEMENT_ADD')" cols="4" class="m-auto">
                <b-link :to="{ name: 'priceManagementAdd' }">
                  {{ $t('negotiated_rate.sidebar.add_price_management') }}
                </b-link>
              </b-col>
            </b-row>
            <!-- submit and reset -->
            <div class="d-flex p-2 mt-2 justify-content-center">
              <b-row>
                <b-col>
                  <b-button :disabled="invalid || negotiatedRateLoading" type="submit" class="mr-1" @click.prevent="submitNegotiatedRate()">
                    {{ $t('action.submit') }}
                  </b-button>
                  <b-button
                    :disabled="untouched || negotiatedRateLoading"
                    type="reset"
                    variant="outline-secondary"
                    class="mr-1"
                    @click.prevent="resetNegotiatedRate()"
                    >{{ $t('action.reset') }}
                  </b-button>
                  <b-button type="button" variant="outline-secondary" @click="hide">
                    <font-awesome-icon icon="ban" class="mr-50" />
                    {{ $t('action.cancel') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { upperFirst, lowerCase } from 'lodash'

import { fetchNegotiatedRateRequest, postNegotiatedRateRequest, patchNegotiatedRateRequest } from '@/request/globalApi/requests/negotiatedRateRequests'

import NegotiatedRateCustomCategories from './NegotiatedRateCustomCategories.vue'

export default {
  name: 'NegotiatedRate',

  components: {
    NegotiatedRateCustomCategories,
  },

  model: {
    prop: 'negotiatedRateEdition',
    event: 'update:negotiated-rate-edition',
  },

  model: {
    prop: 'negotiatedRateEditionMode',
    event: 'update:negotiated-rate-edition-mode',
  },

  model: {
    prop: 'negotiatedRateId',
    event: 'update:negotiated-rate-id',
  },

  props: {
    negotiatedRateEdition: {
      type: Boolean,
      required: true,
    },
    negotiatedRateEditionMode: {
      type: String,
      required: true,
    },
    negotiatedRateId: {
      type: Number,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      negotiatedRateLoading: false,
      negotiatedRate: {
        old: {
          id: null,
          companyName: '',
          intracommunityVatNumber: '',
          status: '',
          cancellationPolicy: {
            id: null,
            name: '',
          },
          priceManagement: {
            id: null,
            name: '',
          },
          startAt: '',
          endAt: '',
        },
        edited: {
          id: null,
          companyName: '',
          intracommunityVatNumber: '',
          status: '',
          cancellationPolicy: {
            id: null,
            name: '',
          },
          priceManagement: {
            id: null,
            name: '',
          },
          startAt: '',
          endAt: '',
        },
        new: {
          id: null,
          companyName: '',
          intracommunityVatNumber: '',
          status: '',
          cancellationPolicy: {
            id: null,
            name: '',
          },
          priceManagement: {
            id: null,
            name: '',
          },
          startAt: '',
          endAt: '',
        },
      },
    }
  },

  computed: {
    ...mapState('priceManagement', ['pricesManagementName', 'pricesManagementNameLoading']),
    ...mapState('cancellationPolicy', ['cancellationPoliciesName', 'cancellationPoliciesNameLoading']),
    ...mapState('enum', ['negotiatedRateStatus', 'negotiatedRateStatusLoading']),

    ...mapGetters('negotiatedRate', ['negotiatedRateById']),
  },

  methods: {
    ...mapActions('cancellationPolicy', ['fetchCancellationPoliciesName']),
    ...mapActions('priceManagement', ['fetchPricesManagementName']),
    ...mapActions('negotiatedRate', ['fetchNegotiatedRates', 'setNegotiatedRatesShowed']),
    ...mapActions('enum', ['fetchEnum2']),

    upperFirst,
    lowerCase,

    resetNegotiatedRate() {
      this.negotiatedRate.edited = this._cloneDeep(this.negotiatedRate.old)
      if (!this.negotiatedRate.edited.cancellationPolicy) {
        this.negotiatedRate.edited.cancellationPolicy = {
          id: null,
          name: '',
        }
      }
      if (!this.negotiatedRate.edited.priceManagement) {
        this.negotiatedRate.edited.priceManagement = {
          id: null,
          name: '',
        }
      }
      this.$refs['negotiated-rate-observer'].reset()
    },

    clearNegotiatedRate() {
      this.negotiatedRate.edited = {
        id: null,
        companyName: '',
        intracommunityVatNumber: '',
        status: '',
        cancellationPolicy: {
          id: null,
          name: '',
        },
        priceManagement: {
          id: null,
          name: '',
        },
        startAt: '',
        endAt: '',
      }
      this.negotiatedRate.old = {
        id: null,
        companyName: '',
        intracommunityVatNumber: '',
        status: '',
        cancellationPolicy: {
          id: null,
          name: '',
        },
        priceManagement: {
          id: null,
          name: '',
        },
        startAt: '',
        endAt: '',
      }
      this.$refs['negotiated-rate-observer'].reset()
    },

    saveNegotiatedRate(negotiatedRate) {
      this.negotiatedRate.edited = this._cloneDeep(negotiatedRate)

      if (this.negotiatedRateEditionMode === 'duplicate') {
        this.negotiatedRate.edited.companyName = `${this.negotiatedRate.edited.companyName}_copy`
        this.negotiatedRate.edited.intracommunityVatNumber = `${this.negotiatedRate.edited.intracommunityVatNumber}_copy`
      }

      this.negotiatedRate.old = this._cloneDeep(this.negotiatedRate.edited)
    },

    submitNegotiatedRate() {
      const negotiatedRate = {
        ...this.negotiatedRate.edited,
        cancellationPolicyId: this.negotiatedRate.edited.cancellationPolicy.id,
        priceManagementId: this.negotiatedRate.edited.priceManagement.id,
      }

      if (this.negotiatedRateEditionMode === 'edit') {
        patchNegotiatedRateRequest(negotiatedRate).then(() => this.fetchNegotiatedRates(true))
      } else {
        negotiatedRate.id = null
        postNegotiatedRateRequest(negotiatedRate).then(() => this.fetchNegotiatedRates(true))
      }

      this.clearNegotiatedRate()
      this.$emit('update:negotiated-rate-edition', false)
    },

    editionChange(isEditing) {
      this.$emit('update:negotiated-rate-edition', isEditing)
      if (!isEditing) {
        this.$emit('update:negotiated-rate-edition-mode', '')
        this.$emit('update:negotiated-rate-id', null)
        this.clearNegotiatedRate()
      }
    },

    fetchNegotiatedRate() {
      const negotiatedRate = this.negotiatedRateById(this.negotiatedRateId)
      if (negotiatedRate) {
        this.saveNegotiatedRate(negotiatedRate)
      } else {
        this.negotiatedRateLoading = true
        fetchNegotiatedRateRequest(this.negotiatedRateId)
          .then(response => {
            this.setNegotiatedRatesShowed({ ...response.data })
            this.saveNegotiatedRate({ ...response.data })
          })
          .finally(() => {
            this.negotiatedRateLoading = false
          })
      }
    },
  },

  watch: {
    negotiatedRateEditionMode: {
      handler(mode) {
        if (mode === 'add') {
          this.saveNegotiatedRate(this.negotiatedRate.new)
        }
        if (mode === 'edit' || mode === 'duplicate') {
          this.fetchNegotiatedRate()
        }
      },
    },
  },

  mounted() {
    this.fetchEnum2('negotiatedRateStatus')
    this.fetchPricesManagementName()
    this.fetchCancellationPoliciesName()
  },
}
</script>
<style lang="scss" scoped></style>
