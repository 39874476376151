<template>
  <div id="negotiated-rate-custom-categories">
    <div :style="{ height: trHeight }" class="repeater-form" :v-if="!categoriesLoading">
      <b-col cols="12">
        <hr />
      </b-col>
      <!-- Row Loop -->
      <b-row v-for="(category, index) in categories" :id="category.categoryId" :key="index" ref="row">
        <!-- Category -->
        <b-col cols="12">
          <validation-provider #default="{ errors }" :name="$t('price_management.service_category')" rules="required">
            <b-form-group :label="$t('price_management.service_category')" label-cols="4" label-for="negotiatedRateCustomCategory">
              <category-tree-select
                id="negotiatedRateCustomCategory"
                :value-id="category.categoryId"
                value-format="id"
                :lang="lang"
                :reduce="val => val.id"
                :active="false"
                :depth="0"
                :multiple="false"
                :synchronize="false"
                @select="e => (category.categoryId = e.id)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Price mode -->
        <b-col cols="12">
          <validation-provider #default="{ errors }" :name="$t('service.price.mode')" rules="required">
            <b-form-group :label="$t('service.price.mode')" label-cols="4" label-for="negotiatedRatePriceMode">
              <b-overlay :show="priceModeLoading">
                <b-form-select id="negotiatedRateyPriceMode" v-model="category.basePriceMode" :options="priceMode" :disabled="priceModeLoading" size="sm" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-overlay>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Base price -->
        <b-col cols="12">
          <validation-provider #default="{ errors }" :name="$t('price_management.group.base_pric')" rules="required">
            <b-form-group :label="$t('price_management.group.base_price')" label-cols="4" label-for="negotiatedRateBasePrice">
              <b-form-input id="negotiatedRateBasePrice" v-model="category.basePriceExcludingTaxes" size="sm" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Remove Button -->
        <b-col cols="12" class="mb-50">
          <b-button id="removeCategory" variant="outline-danger" size="sm" @click="removeCategory(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>{{ $t('action.delete') }}</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </div>
    <b-row class="pl-1">
      <b-button id="addCategory" @click="addCategory()">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t('action.add') }}</span>
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { heightTransition } from '@core/mixins/ui/transition'
import CategoryTreeSelect from '@/components/CategoryTreeSelect.vue'

export default {
  name: 'NegotiatedRateCustomCategories',

  components: {
    CategoryTreeSelect,
  },

  mixins: [heightTransition],

  props: {
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      categoriesLoading: true,
    }
  },

  computed: {
    ...mapState('enum', ['priceMode', 'priceModeLoading']),
    ...mapState('category', ['categoriesName', 'categoriesNameLoading']),
    ...mapState('appConfig', ['lang']),
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    addCategory() {
      this.categories.push({
        categoryId: null,
        categoryName: {},
        basePriceExcludingTaxes: '',
        basePriceMode: '',
      })
      this.$nextTick(() => {
        if (this.$refs.row && this.$refs.row.length > 0) {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        }
      })
      this.initTrHeight()
    },

    removeCategory(index) {
      this.categories.splice(index, 1)
      if (this.$refs.row && this.$refs.row.length > 0) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
      this.initTrHeight()
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.row) {
          this.trSetHeight(this.$refs.row.scrollHeight)
        }
      })
    },
  },

  watch: {
    categories: {
      handler() {
        this.$emit('categoriesUpdated', this.categories)
        this.$forceUpdate()
      },
      deep: true,
    },
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },

  mounted() {
    this.categoriesLoading = true
    this.fetchEnum2('priceMode')
    if (this.categories.length === 0) {
      this.categories.push({
        categoryId: null,
        categoryName: {},
        basePriceExcludingTaxes: '',
        basePriceMode: '',
      })
    }
    this.initTrHeight()
    this.categoriesLoading = false
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
